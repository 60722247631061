import MicroEvent from '@vivotek/lib-utility/microevent';
import RawPlayer from './RawPlayer';
import AbstractPlayer from './AbstractPlayer';

class CanvasPlayer extends AbstractPlayer {
  constructor(options) {
    super(options);

    const {
      width, height, codec,
      workerLibde264Path, workerLibde265Path
    } = options;

    this.workerLibde265Path = workerLibde265Path;
    this.workerLibde264Path = workerLibde264Path;

    const hevcCanvas = document.createElement('canvas');

    this.uninitedPacketBuffer = [];

    hevcCanvas.setAttribute('width', width);
    hevcCanvas.setAttribute('height', height);

    const rawPlayeroptions = this.gainRawPlayerOptions({ canvas: hevcCanvas, width, height, codec });
    const player = new RawPlayer(rawPlayeroptions);

    player.on('play', () => this.onPlay());
    player.on('timeupdate', () => this.onTimeupdate());
    player.on('pause', () => this.onPause());
    player.on('error', (err) => this.trigger('error', new Error(err)));
    player.on('inited', () => this.onInited(player));

    this.canvas = MicroEvent.mixin(player.canvas);
    this.player = player;
  }

  onInited(player) {
    super.onInited(player);
    this.uninitedPacketBuffer.forEach(({packet, info}) => {
      player.decodeOneFrame(packet, info);
    });
    this.uninitedPacketBuffer.length = 0;
  }

  onPause() {
    this.canvas.trigger('pause');
  }

  stop() {
    this.player.stop();
    super.stop();
  }

  switch() {
    super.switch();
    this.player.clear();
  }

  gainRawPlayerOptions({
    canvas, width, height, codec = 'HEVC'
  }) {
    const workerSrc = (codec === 'HEVC' || codec === 'H265')
      ? this.workerLibde265Path
      : this.workerLibde264Path;
    return {
      codec, width, height,
      canvas, workerSrc,
      autoplay: false,
    };
  }

  appendPacket({ packet, info }) {
    super.appendPacket({ packet, info });

    const {
      isInited, player, uninitedPacketBuffer,
      playbackReady, playing, playbackOffset,
    } = this;

    if (isInited) {
      player.decodeOneFrame(packet, info);
    } else {
      uninitedPacketBuffer.push({ packet, info });
    }

    const bufferedLast = (Number(player.getBufferedLast()) - 33) / 1000;

    if (player && playbackReady && !playing && bufferedLast >= playbackOffset) {
      player.currentTime = playbackOffset;
      player.play();
    }
  }
}

export default MicroEvent.mixin(CanvasPlayer);
