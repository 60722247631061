import MicroEvent from '@vivotek/lib-utility/microevent';
import ModuleSmartStream from '../ssmux';
import RawPlayer from '../player/RawPlayer';
import { isSafari, appendBuffer } from '../utils/rtsp_tools';
import setSsmuxDebugLevel from '../utils/set_ssmux_debug_level';
import xml2json from '../utils/xml2json';
import PLUGINFREE from '../constants/pluginfree';


function Liveview(options) {
  this.options = options;
  this.rtspChannel = options.rtspChannel;
  this.url = options.url || '';
  this.mute = !!options.mute;
  this.volume = options.volume || 1;
  this.username = options.username;
  this.sessionId = options.sessionId;
  this.autoplay = options.autoplay || false;
  this.playing = false;
  this.stopping = false;
  this.ssmuxType = 0;
  this.safariPause = isSafari;
  this.status = 'TEARDOWN';
  this.rtspSession = '';
  // compute fps from streeaming
  this.frameCount = 0;
  this.fps = 0;

  this.isFisheye = false;
  this.speedUp = false;

  this.COMBINE_PACKET_NUMBER = 2;
  this.RESET_AUDIO_EACH_MIN = 6;
  this.KEEP_PASSED_LENGTH = 60;
  this.SOURCEBUFFER_LOWER_LENGTH = 0.8;
  this.SOURCEBUFFER_UPPER_LENGTH = 1.8;

  this.queue = [];
  this.audioQueue = [];
  this.backupAudioPackets = [];
  this.backupAudioPacketsLength = 30;

  this.audioContext = null;
  this.gainNode = null;
  this.audioInited = false;
  this.audioContextStart = 0;

  this.videoTimeMapNotify = {};
  this.audioTimestampMapPacket = {};
  this.metjTimestampMapNotify = {};
  this.prevNotify = null;

  this.fixedPlaybackRate = options.fixedPlaybackRate || false;
  this.workerLibde265Path = options.workerLibde265Path;
  this.workerLibde264Path = options.workerLibde264Path;

  this.checkPauseForSafari = function checkPauseForSafari() {
    if (!isSafari) { return; }

    this.unsetResync();

    this.audioInited = false;
    this.audioQueue = [];
    this.audioTimestampMapPacket = {};

    const bufferLength = this.getBufferLength();
    // check buffer's length
    if (bufferLength < 0) {
      this.safariPause = true;
    }
  };
}

Liveview.getAudioWindow = function getAudioWindow(n, f) {
  const arr = [];

  for (let i = 0; i < n; i += 1) {
    arr[i] = (i < n / 2) ? 1 - f ** i : 1 - f ** (n - i);
  }

  return arr;
};

Liveview.getMapList = function getMapList(map) {
  return Object.keys(map).sort((a, b) => Number(a) - Number(b));
};

Liveview.prototype.createVideo = function createVideo({
  video, queue, volume, mute, autoplay
}) {
  const videoEl = MicroEvent.mixin(video || document.createElement('video'));

  this.initVideo(videoEl, autoplay);

  const createMediasource = this.createMediasource(videoEl, queue);
  const createAudio = this.createAudio(volume, mute);

  return Promise.all([createMediasource, createAudio])
    .then(([[mediaSource, sourceBuffer], [audioContext, gainNode]]) => {
      videoEl.getBufferLength = () => {
        try {
          return sourceBuffer.buffered.end(0) - videoEl.currentTime;
        } catch (err) {
          console.warn(err);

          return 0;
        }
      };

      videoEl.cutSourceBufferHead = () => {
        const { sourceBufferStart } = this;
        const bufLength = this.KEEP_PASSED_LENGTH;

        try {
          if (videoEl.currentTime - sourceBufferStart > bufLength * 2 && !sourceBuffer.updating) {
            sourceBuffer.remove(sourceBufferStart, sourceBufferStart + bufLength);

            this.sourceBufferStart += bufLength;
          }
        } catch (err) {
          console.error(err);
        }
      };

      return {
        player: videoEl,
        mediaSource,
        sourceBuffer,
        audioContext,
        gainNode,
      };
    });
};

Liveview.prototype.initVideo = function initVideo(video, autoplay=false) {
  video.muted = true;
  video.autoplay = autoplay;
  video.addEventListener('play', () => this.onVideoPlay());
  video.addEventListener('timeupdate', () => {
    if (video.paused) { return; }

    this.onVideoTimeUpdate();
  });
  video.addEventListener('pause', () => {
    this.onVideoPause();
  });
  video.addEventListener('loadeddata', () => {
    if (video.readyState === 4 && !window.document.hasFocus()) {
      video.play();
    }
  });
};

Liveview.prototype.removePlayer = function removePlayer() {
  const { player } = this;

  if (!player) { return; }

  if (player instanceof RawPlayer) {
    this.removeRawPlayer(player);
  } else {
    this.removeVideo(player);
  }

  this.videoTimeMapNotify = {};
};

Liveview.prototype.removeRawPlayer = function removeRawPlayer(rawPlayer) {
  rawPlayer.release();
};

Liveview.prototype.removeVideo = function removeVideo(video) {
  const { src } = video;
  video.pause();
  video.src = '';
  video.load();
  URL.revokeObjectURL(src);
};

Liveview.prototype.gainRawPlayerOptions = function gainRawPlayerOptions({
  canvas, width, height, codec = 'HEVC'
}) {
  const workerSrc = (codec === 'HEVC' || codec === 'H265')
    ? this.workerLibde265Path
    : this.workerLibde264Path;
  return {
    codec,
    workerSrc,
    canvas,
    width,
    height,
  };
};

Liveview.prototype.createRawPlayer = function createRawPlayer({ width, height, codec }) {
  const hevcCanvas = document.createElement('canvas');

  hevcCanvas.setAttribute('width', width);
  hevcCanvas.setAttribute('height', height);

  const options = this.gainRawPlayerOptions({ canvas: hevcCanvas, width, height, codec });
  const player = new RawPlayer(options);
  const simulatVideo = MicroEvent.mixin(player.canvas);

  player.on('play', () => {
    simulatVideo.trigger('play');
    this.onVideoPlay();
  });
  player.on('timeupdate', () => {
    this.onVideoTimeUpdate();
  });
  player.on('pause', () => {
    this.onVideoPause();
  });
  player.on('error', (warn) => this.trigger('error', new Error(warn)));

  const createPlayer = new Promise((resolve) => {
    player.on('inited', () => resolve({ rawPlayer: player }));
  });
  const createAudio = this.createAudio(this.volume, this.mute);

  return Promise.all([createPlayer, createAudio])
    .then(([{ rawPlayer }, [audioContext, gainNode]]) => {
      rawPlayer.getBufferLength = () => {
        const length = player.getBufferedLast() - player.getBufferedFirst();

        return isNaN(length) ? 0 : length / 1000;
      };

      rawPlayer.cutSourceBufferHead = function() {
        // auto cut in RawPlayer
      };

      return { player: rawPlayer, audioContext, gainNode };
    });
};

Liveview.prototype.getPlayer = function getPlayer() {
  if (this.player instanceof RawPlayer) {
    return this.player.canvas;
  }
  return this.player;
};

Liveview.prototype.getPlayerCurrentTime = function getPlayerCurrentTime() {
  if (!this.player) {
    return 0;
  }

  return this.player.currentTime;
};

Liveview.prototype.onVideoPlay = function onVideoPlay() {
  if (this.playing) { return; }
  this.playing = true;
  this.setPlayerTimeout();
  this.resumeAudio();
  this.trigger('play', this.getPlayer());
};

Liveview.prototype.onVideoPause = function onVideoPause(evt) {
  if (!window.document.hasFocus()) {
    this.player.play();
    return;
  }

  if (this.audioContext) {
    this.audioContext.suspend();
  }

  if (!isSafari) { return; }

  this.checkPauseForSafari();
};

Liveview.prototype.onSourceBufferUpdated = function onSourceBufferUpdated(video, sourceBuffer, queue) {
  queue.shift();

  const play = () => video.play().catch((err) => {
    this.trigger('error', err);
  });

  if (this.safariPause) {
    play().then((_) => {
      this.safariPause = false;
    });
  }

  const buffered = this.getBufferLength();

  if (!this.playing && buffered >= this.SOURCEBUFFER_LOWER_LENGTH) {
    play();
  }

  if (!queue.length || this.mediaSource.readyState !== 'open' || this.sourceBuffer.updating) { return; }
  sourceBuffer.appendBuffer(queue[0]);
};

Liveview.prototype.onVideoTimeUpdate = function onVideoTimeUpdate() {
  if (this.checkShouldSkipTimeUpdate()) { return; }

  this.setPlayerTimeout();

  const currentTime = this.player.currentTime * 1000;

  this.triggerExpireNotify(currentTime);
  this.adjustPlaybackRate();
  this.cutSourceBufferHead();

  if (this.audioInited) { return; }

  this.initAudio();

  if (this.resetInited || !this.audioQueue.length) { return; }

  this.setResync();
  this.processAudioQueue(this.audioQueue)
    .then(this.playAudioPacket.bind(this));
};

Liveview.prototype.triggerExpireNotify = function triggerExpireNotify(currentTime) {
  const { videoTimeMapNotify } = this;
  const videoTimeMapKeysList = Liveview.getMapList(videoTimeMapNotify);

  videoTimeMapKeysList.forEach((time) => {
    if (currentTime < time) { return; }

    const orginInfo = videoTimeMapNotify[time];
    const notify = this.createNotify(orginInfo);

    this.triggerNotify(notify);
    // TODO: trigger streaming event

    delete videoTimeMapNotify[time];
    // trigger metj event
    const { metjTimestampMapNotify } = this;
    const metjTimeMapKeysList = Liveview.getMapList(metjTimestampMapNotify);
    const { stream } = orginInfo.timestamp; // timestamp from camera

    metjTimeMapKeysList.forEach((timestamp) => {
      const timestampInList = Number(timestamp);

      if (stream < timestampInList) { return; }

      const metjs = metjTimestampMapNotify[timestamp];

      metjs.forEach((metj) => {
        this.trigger('metj', metj);
      });

      delete metjTimestampMapNotify[timestamp];
    });
  });
};

Liveview.prototype.createNotify = function createNotify(frameInfo) {
  const timestamp = { ...frameInfo.timestamp, ...frameInfo.timestamp.display };
  delete timestamp.display;

  return { ...frameInfo, timestamp };
};

Liveview.prototype.triggerNotify = function triggerNotify(notify) {
  this.trigger('notify', notify);
  this.prevNotify = notify;
};

Liveview.prototype.adjustPlaybackRate = function adjustPlaybackRate() {
  if (this.fixedPlaybackRate) { return; } // no need to adjust in 'playback'

  const { player, gainNode } = this;
  // check buffer length and speed up if queued more.
  const bufferLength = this.getBufferLength();
  // check buffer's length
  if (bufferLength > this.SOURCEBUFFER_UPPER_LENGTH
    && player.playbackRate === 1) {
    console.warn('speed up playbackRate', 1.5);

    player.playbackRate = 1.5;

    if (gainNode) {
      gainNode.gain.value = 0;
    }

    this.speedUp = true;
  } else if (bufferLength < this.SOURCEBUFFER_LOWER_LENGTH
    && player.playbackRate !== 1) {
    console.warn('playbackRate', 1);

    player.playbackRate = 1;

    this.speedUp = false;
    this.resetAudio();

    if (!this.mute && gainNode) {
      gainNode.gain.value = this.volume;
    }
  }
};

Liveview.prototype.cutSourceBufferHead = function cutSourceBufferHead() {
  this.player.cutSourceBufferHead();
};

Liveview.prototype.createMediasource = function createMediasource(video, queue) {
  if (!video) {
    return Promise.reject();
  }

  const mediaSource = new window.MediaSource();

  return new Promise((resolve) => {
    this.onSourceOpen = () => {
      mediaSource.duration = Infinity;

      const sourceBuffer = mediaSource.addSourceBuffer('video/mp4; codecs="avc1.42E01E"');

      this.onUpdateend = this.onSourceBufferUpdated.bind(this, video, sourceBuffer, queue);
      sourceBuffer.addEventListener('updateend', this.onUpdateend);

      resolve([mediaSource, sourceBuffer]);
    };
    mediaSource.addEventListener('sourceopen', this.onSourceOpen);

    video.src = URL.createObjectURL(mediaSource);
  });
};

Liveview.prototype.getBufferLength = function getBufferLength() {
  if (this.player) {
    return this.player.getBufferLength();
  }

  return 0;
};

Liveview.prototype.createAudio = function createAudio(volume, mute) {
  const AudioContext = window.AudioContext
                       || window.webkitAudioContext;

  const audioContext = new AudioContext();
  let gainNode;

  try {
    gainNode = audioContext.createGain();
    gainNode.connect(audioContext.destination);
    gainNode.gain.value = mute ? 0 : volume;
  } catch (err) {
    console.warn('createGain fail', err);
  }

  return Promise.resolve([audioContext, gainNode]);
};

Liveview.prototype.removeAudio = function removeAudio(audioContext, gainNode) {
  if (gainNode) {
    gainNode.disconnect();
  }

  if (audioContext) {
    audioContext.close();
  }

  this.audioInited = false;
  this.audioQueue = [];
  this.audioTimestampMapPacket = {};
};

Liveview.prototype.initAudio = function initAudio() {
  const { audioContext, audioTimestampMapPacket } = this;

  if (!audioContext || audioContext.state === 'suspended') { return; }

  const currentTime = this.player.currentTime * 1000;
  // element's format { packet, info, sampleRate, duration } in audioTmp
  // ref in processAudio method
  const refFrameTimestamp = this.getReferenceVideoFrameTimestamp();
  const audioTmp = Liveview.getMapList(audioTimestampMapPacket)
    .filter((timestamp) => Number(timestamp) >= refFrameTimestamp.stream)
    .map((timestamp) => audioTimestampMapPacket[timestamp]);

  if (!audioTmp.length || !refFrameTimestamp) { return; }

  const firstAudioInfo = audioTmp[0].info;
  const audioPlayTime = this.genAudioPlayTime(
    firstAudioInfo.timestamp, refFrameTimestamp, currentTime, audioContext.currentTime * 1000
  );
  // the audio reference start time
  this.audioContextStart = audioPlayTime - firstAudioInfo.timestamp.audio;
  this.audioInited = true;
  // move audio from temp to the audioQueue
  audioTmp.forEach((audio) => {
    this.audioQueue.push(audio);
  });
};

Liveview.prototype.resumeAudio = function resumeAudio() {
  if (!this.audioContext || this.audioContext.state !== 'suspended') { return; }

  try {
    this.audioContext.resume();

    const refFrameTimestamp = this.getReferenceVideoFrameTimestamp();
    const { audioTimestampMapPacket } = this;

    Liveview.getMapList(audioTimestampMapPacket).forEach((timestamp) => {
      if (timestamp >= refFrameTimestamp.stream) { return; }

      delete audioTimestampMapPacket[timestamp];
    });

    this.initAudio();
  } catch (err) {
    console.warn(err);
  }
};

Liveview.prototype.getReferenceVideoFrameTimestamp = function getReferenceVideoFrameTimestamp() {
  const videoTimeMapKeysList = Liveview.getMapList(this.videoTimeMapNotify);
  const firstVideoTime = videoTimeMapKeysList[0];
  // get frame info from videoTimeMap or pupped notify
  const firstFrameInfo = this.videoTimeMapNotify[firstVideoTime];
  const prevFrameInfo = this.prevNotify;

  if (!prevFrameInfo && !firstFrameInfo) { return 0; }

  return firstFrameInfo ? firstFrameInfo.timestamp : prevFrameInfo.timestamp;
};

Liveview.prototype.genAudioPlayTime = function genAudioPlayTime(
  audioTimeInfo, videoFrameTimeInfo, videoCurrentTime, audioCurrentTime
) {
  const currentTimestamp = videoFrameTimeInfo.stream
                           + (videoCurrentTime - videoFrameTimeInfo.video);
  return audioCurrentTime + (audioTimeInfo.stream - currentTimestamp);
};

Liveview.prototype.setResync = function setResync() {
  this.unsetResync();

  this.resetInited = setTimeout(this.resetAudio.bind(this),
    this.RESET_AUDIO_EACH_MIN * 60 * 1000);
};

Liveview.prototype.unsetResync = function unsetResync() {
  if (!this.resetInited) { return; }

  clearTimeout(this.resetInited);
  this.resetInited = null;
};

Liveview.prototype.resetAudio = function resetAudio() {
  this.unsetResync();
  this.processAudioQueue(this.audioQueue)
    .then(this.playAudioPacket.bind(this),
      (err) => console.warn('audioQueue is empty'));

  this.audioInited = false;
  this.audioQueue = [];
  this.audioTimestampMapPacket = {};
};

Liveview.prototype.playAudioPacket = function playAudioPacket([packet, startTime, sampleRate]) {
  const { audioContext, gainNode } = this;

  if (!audioContext || !gainNode || this.speedUp) { return; }

  const source = audioContext.createBufferSource();
  const win = Liveview.getAudioWindow(packet.length, 0.99);
  let audioBuffer;

  if (startTime < 0) { return; }

  if (isSafari) {
    audioBuffer = audioContext.createBuffer(1, packet.length, 44100);
    source.playbackRate.value = 8000 / 44100;
  } else {
    audioBuffer = audioContext.createBuffer(1, packet.length, sampleRate);
  }

  // put pcm data(Float32Array) in audiobuffer
  for (let sample = 0; sample < packet.length; sample += 1) {
    audioBuffer.getChannelData(0)[sample] = packet[sample] * win[sample];
  }

  const audioStartTime = startTime || 0;

  source.buffer = audioBuffer;
  source.connect(gainNode);
  source.start(audioStartTime / 1000);
};

Liveview.prototype.processVideo = function processVideo(packet) {
  const { mediaSource, sourceBuffer, queue } = this;

  if (!mediaSource || mediaSource.readyState !== 'open') { return; }

  if (queue.length === 0 && sourceBuffer && !sourceBuffer.updating) {
    sourceBuffer.appendBuffer(packet);
  }
  queue.push(packet);
};

Liveview.prototype.processStreamNotify = function processStreamNotify(notify) {
  if (!notify || !notify.timestamp) { return; }
  // catch notify and wait for trigger in method triggerExpireNotify
  this.videoTimeMapNotify[notify.timestamp.video] = notify;
};

Liveview.prototype.processVideoEvent = function processVideoEvent(videoPacket) {
  const { videoNotify } = this;
  const packetSlice = videoPacket.slice();
  this.videoNotify = null;

  if (!this.player) {
    if (this.packet_bucket) {
      this.packet_bucket.push({ packet: packetSlice, info: videoNotify });
      return;
    }

    this.packet_bucket = [{ packet: packetSlice, info: videoNotify }];

    // console.log('create a <video> as the H264 player');

    this.createVideo({
      video: this.options.video,
      queue: this.queue,
      volume: this.volume,
      mute: this.mute,
      autoplay: this.autoplay
    }).then(({
      player,
      mediaSource, sourceBuffer,
      audioContext, gainNode,
    }) => {
      this.player = player;
      this.mediaSource = mediaSource;
      this.sourceBuffer = sourceBuffer;
      this.audioContext = audioContext;
      this.gainNode = gainNode;
      this.sourceBufferStart = 0;

      this.packet_bucket.forEach(({ packet, info }) => {
        this.processVideo(packet);
        this.processStreamNotify(info);
      });
    }).finally(() => {
      this.packet_bucket = null;
    });

    return;
  }

  this.processVideo(packetSlice);
  this.processStreamNotify(videoNotify);
};

Liveview.prototype.processHEVCEvent = function processHEVCEvent(hevcPacket) {
  const { videoNotify } = this;
  const packetSlice = hevcPacket.slice();

  if (!videoNotify) { return; }

  if (!this.player) {
    if (this.packet_bucket) {
      this.packet_bucket.push({ packet: packetSlice, info: videoNotify });
      return;
    }

    this.packet_bucket = [{ packet: packetSlice, info: videoNotify }];

    // console.log('create a RawPlayer as the H265 player');

    this.createRawPlayer({
      codec: videoNotify.codec, ...videoNotify.resolution
    }).then(({ player, audioContext, gainNode }) => {
      this.player = player;
      this.audioContext = audioContext;
      this.gainNode = gainNode;

      this.packetBuffer = this.packet_bucket;
      this.packet_bucket.forEach(({ packet, info }) => {
        player.decodeOneFrame(packet, info);
        this.processStreamNotify(info);
      });
      this.packet_bucket = null;
    }).finally(() => {
      this.packet_bucket = null;
    });

    return;
  }

  if (typeof this.player.decodeOneFrame !== 'function') { return; }

  this.player.decodeOneFrame(packetSlice, videoNotify);
  this.processStreamNotify(videoNotify);

  this.videoNotify = null;
};

Liveview.prototype.processAudio = function processAudio(packet, sampleRate, duration, info) {
  const { audioQueue } = this;

  this.backupAudio(packet, sampleRate, duration, info);

  if (!info) { return; }

  if (!this.audioInited) {
    // audio is not ready, temp it. They will be moved in `initAudio`.
    this.audioTimestampMapPacket[info.timestamp.stream] = {
      packet, info, sampleRate, duration
    };

    return;
  }

  audioQueue.push({
    packet, info, sampleRate, duration
  });
};

Liveview.prototype.backupAudio = function backupAudio(packet, sampleRate, duration, info) {
  this.backupAudioPackets.push({packet, sampleRate, duration, info});
  if (this.backupAudioPackets.length > this.backupAudioPacketsLength) {
    this.backupAudioPackets.shift();
  }
}

Liveview.prototype.processAudioQueue = function processAudioQueue(audioQueue, isCleanQueue) {
  if (!audioQueue.length) { return Promise.reject(); }

  const packetInfo = audioQueue[0].info;
  const packetSampleRate = audioQueue[0].sampleRate;

  let packetCombine = new ArrayBuffer();
  let packetPair;
  const limit = isCleanQueue ? 0 : 1;

  while (audioQueue.length > limit) {
    packetPair = audioQueue.shift();
    packetCombine = appendBuffer(packetCombine, packetPair.packet.buffer);
  }

  if (audioQueue.length) {
    packetCombine = appendBuffer(packetCombine, audioQueue[0].packet.slice(0, 256).buffer);
  }

  const packetStartTime = this.audioContextStart + packetInfo.timestamp.audio;

  return Promise.resolve([new Float32Array(packetCombine), packetStartTime, packetSampleRate]);
};

Liveview.prototype.processAudioEvent = function processAudioEvent(packet, sampleRate, duration) {
  this.processAudio(packet.slice(), sampleRate, duration, this.audioNotify);
  this.audioNotify = null;
  // combine the PCM
  if (this.audioQueue.length <= this.COMBINE_PACKET_NUMBER) { return; }

  this.processAudioQueue(this.audioQueue)
    .then(this.playAudioPacket.bind(this));
};

Liveview.prototype.processInfo = function processInfo(info) {
  if (!info.timestamp) {
    if (info.error) {
      this.handleInfoError(info.error);
    }
    return;
  }
  // streaming info
  if (info.timestamp.video !== undefined) {
    if (PLUGINFREE.SUPPORT_VIDEO_CODEC.indexOf(info.codec) < 0) {
      this.trigger('notify', this.createNotify(info));
      const error = `codec: ${info.codec} is not support`;
      this.trigger('error', new Error(error));
    }
    // video info
    this.videoNotify = info;
    this.isFisheye = !!info.fisheye;

    if (!this.frameCount) {
      this.frameCount = 1;
      this.frameStart = info.timestamp.stream;
    } else {
      this.fps = Math.round((this.frameCount / (info.timestamp.stream - this.frameStart)) * 1000);
      this.frameCount += 1;
    }
  } else if (info.timestamp.audio) {
    // audio info
    this.audioNotify = info;
  }
};

Liveview.prototype.handleInfoError = function handleInfoError(error) {
  return this.trigger('error', new Error(error));
};

Liveview.prototype.processInfoEvent = function processInfoEvent(ptr) {
  let streamInfo;
  try {
    streamInfo = JSON.parse(ptr);
  } catch (err) {
    console.warn(err, ptr);
    return;
  }

  this.processInfo(streamInfo);
};

Liveview.prototype.processMETJEvent = function processMETJEvent(ptr) {
  let streamInfo;
  try {
    streamInfo = JSON.parse(ptr);
  } catch (err) {
    console.warn(err, ptr);
    return;
  }

  let timestamp;
  switch (streamInfo.Tag) {
    case 'MetaData':
      timestamp = new Date(streamInfo.Frame.UtcTime).getTime();
      break;
    case 'Event':
      try {
        const firstData = streamInfo.Data[0];
        const arrKey = Object.keys(firstData).filter((key) => Array.isArray(firstData[key]))[0];
        const [firstInfo] = firstData[arrKey];

        timestamp = new Date(firstInfo.Time).getTime();
      } catch (err) {
        console.warn(err);
        return;
      }
      break;
    default:
      break;
  }

  if (timestamp !== undefined) {
    if (this.metjTimestampMapNotify[timestamp]) {
      this.metjTimestampMapNotify[timestamp].push(streamInfo);
    } else {
      this.metjTimestampMapNotify[timestamp] = [streamInfo];
    }
  }
};

Liveview.prototype.processMETXEvent = function processMETXEvent(text) {
  const xmlDoc = new DOMParser().parseFromString(text, 'text/xml');
  const xmlJsonStr = xml2json(xmlDoc);
  const xmlJson = JSON.parse(xmlJsonStr);
  const nodes = xmlDoc.querySelectorAll('[UtcTime]');

  if (nodes.length <= 0) { return; }

  const utcTime = nodes[0].getAttribute('UtcTime');
  const timestamp = new Date(utcTime).getTime();

  if (this.metjTimestampMapNotify[timestamp]) {
    this.metjTimestampMapNotify[timestamp].push(xmlJson);
  } else {
    this.metjTimestampMapNotify[timestamp] = [xmlJson];
  }
};

Liveview.prototype.createSsmux = function createSsmux(ssmuxType) {
  const ssmux = new ModuleSmartStream();
  const processVideoEvent = this.processVideoEvent.bind(this);
  const processAudioEvent = this.processAudioEvent.bind(this);
  const processInfoEvent = this.processInfoEvent.bind(this);
  const processHEVCEvent = this.processHEVCEvent.bind(this);
  const processMETJEvent = this.processMETJEvent.bind(this);
  const processMETXEvent = this.processMETXEvent.bind(this);

  ssmux.initial(ssmuxType);
  ssmux.setFMP4Callback(processVideoEvent);
  ssmux.setPCMCallback(processAudioEvent);
  ssmux.setInfoCallback(processInfoEvent);
  ssmux.setHEVCCallback(processHEVCEvent);
  ssmux.setMETJCallback(processMETJEvent);
  ssmux.setMETXCallback(processMETXEvent);

  return Promise.resolve(ssmux);
};

Liveview.prototype.releaseSsmux = function releaseSsmux(ssmux) {
  ssmux.setFMP4Callback(null);
  ssmux.setPCMCallback(null);
  ssmux.setInfoCallback(null);
  ssmux.setHEVCCallback(null);
  ssmux.setMETJCallback(null);
  ssmux.setMETXCallback(null);
  ssmux.release();
  // ssmux.delete(); // <<<<< call delete if you need to delete ssmux instance
};

Liveview.prototype.sendOptions = function sendOptions(url) {
  if (this.status !== 'TEARDOWN') {
    return Promise.reject();
  }

  return this.rtspChannel.sendOptions(url).then(() => {
    this.status = 'OPTIONS';
  });
};

Liveview.prototype.getAuthParams = function getAuthParams() {
  let params;

  if (this.username || this.sessionId) {
    params = {};

    if (this.username) {
      params.username = this.username;
    }
    if (this.sessionId) {
      // eslint-disable-next-line no-underscore-dangle
      params._SID_ = this.sessionId;
    }
  }

  return params;
};

Liveview.prototype.sendDescribe = function sendDescribe(url) {
  const { rtspChannel } = this;

  this.trackCallback = this.trackCallback.bind(this);
  this.trackInfoCallback = this.processInfo.bind(this);

  const setTracks = (tracks) => {
    rtspChannel.tracks = tracks;

    tracks.forEach((track) => {
      switch (track.mediatype) {
        case 'video':
        case 'audio':
          rtspChannel.on(track.trackID, this.trackCallback);
          break;
        case 'application':
          // if (track.mimetype === 'application/json') {
          rtspChannel.on(track.trackID, this.trackInfoCallback);
          // }
          break;
        default:
          break;
      }
    });
  };

  if (this.status !== 'OPTIONS') {
    return Promise.reject();
  }

  return new Promise((resolve, reject) => {
    const done = ([status, tracks]) => {
      if (this.status === 'TEARDOWN') {
        reject();
      }

      setTracks(tracks);

      this.status = 'DESCRIBE';

      resolve([status, tracks]);
    };

    const params = this.getAuthParams();

    rtspChannel.sendDescribe(url, params /* , true */).then(done, () => {
      // try again without backchannel
      rtspChannel.sendDescribe(url, params).then(done, reject);
    });
  });
};

Liveview.prototype.sendSetup = function sendSetup(url) {
  const { rtspChannel } = this;

  if (this.status !== 'DESCRIBE' && this.status !== 'PLAY') {
    return Promise.reject();
  }

  return new Promise((resolve, reject) => {
    Promise.all(
      rtspChannel.tracks.map((track) => {
        const rPathWithParams = /(\S+)\?(\S*)/;

        if (rPathWithParams.test(url)) {
          return rtspChannel.sendSetup(url.replace(
            rPathWithParams, (_, p1, p2) => `${p1}/trackID=${track.trackID}?${p2}`
          ));
        }
        return rtspChannel.sendSetup(`${url}/trackID=${track.trackID}`);
      })
    ).then((responses) => {
      if (this.status === 'TEARDOWN') {
        return reject();
      }

      const session = responses[0][1];

      this.status = 'SETUP';

      return resolve(['200', session]);
    }, reject);
  });
};

Liveview.prototype.sendPlay = function sendPlay(url, session) {
  const allowStatus = ['SETUP', 'PLAY', 'PAUSE'];
  if (allowStatus.indexOf(this.status) < 0) {
    return Promise.reject();
  }

  return this.rtspChannel.sendPlay(url, session).then(() => {
    this.status = 'PLAY';
  });
};

Liveview.prototype.sendPause = function sendPause(url, session) {
  if (this.status !== 'PLAY') {
    return Promise.reject();
  }
  if (this.status === 'PAUSE') {
    return Promise.resolve();
  }

  return this.rtspChannel.sendPause(url, session).then(() => {
    this.status = 'PAUSE';
  });
};

Liveview.prototype.sendChange = function sendChange(url, session, rate) {
  const allowStatus = ['PLAY', 'PAUSE'];
  if (!allowStatus.includes(this.status)) {
    return Promise.reject();
  }

  return this.rtspChannel.sendChange(url, session, rate);
};

Liveview.prototype.sendTeardown = function sendTeardown(url, session) {
  const { rtspChannel, trackCallback, trackInfoCallback } = this;

  return rtspChannel.sendTeardown(url, session).finally(() => {
    this.status = 'TEARDOWN';

    if (!rtspChannel.tracks) { return; }
    rtspChannel.tracks.forEach((track) => {
      rtspChannel.off(track.trackID, track.mediatype === 'application/json' ? trackInfoCallback : trackCallback);
      rtspChannel.removeTrack(track.trackID);
    });
  });
};

Liveview.prototype.trackCallback = function trackCallback(packet) {
  const arr = new Uint8Array(packet);
  // eslint-disable-next-line no-underscore-dangle
  const buf = this.ssmux._malloc(arr.length);

  this.ssmux.HEAP8.set(arr, buf);
  this.ssmux.inputPacketV1(buf, arr.length, false);
  // eslint-disable-next-line no-underscore-dangle
  this.ssmux._free(buf);
};

Liveview.prototype.play = function play() {
  const { url } = this;
  const sendOptions = () => this.sendOptions(url);
  const sendDescribe = () => this.sendDescribe(url);
  const sendSetup = () => this.sendSetup(url)
    .then(([status, session]) => {
      this.rtspSession = session;
    });
  const sendPlay = () => this.sendPlay(url, this.rtspSession);
  const createSsmux = () => this.createSsmux(this.ssmuxType)
    .then(setSsmuxDebugLevel)
    .then((ssmux) => {
      this.ssmux = ssmux;
    });

  const playSteps = () => sendOptions()
    .then(sendDescribe)
    .then(sendSetup)
    .then(createSsmux)
    .then(sendPlay)
    .catch((e) => {
      console.error(e);

      return Promise.reject(e);
    });

  if (this.status === 'TEARDOWN') {
    return playSteps();
  }
  return this.stop().then(() => playSteps());
};

Liveview.prototype.stop = function stop() {
  if (this.status === 'TEARDOWN') {
    return Promise.resolve();
  }

  this.clearPlayerTimeout();
  this.unsetResync();
  if (this.onUpdateend && this.sourceBuffer) {
    this.sourceBuffer.removeEventListener('updateend', this.onUpdateend);
  }

  if (this.player instanceof RawPlayer) {
    this.player.stop();
  } else if (this.player && this.player.pause) {
    this.player.pause();
  }
  this.stopping = true;
  return this.sendTeardown(this.url, this.rtspSession)
    .catch((err) => {
      console.warn(err);
    })
    .finally(() => {
      this.stopping = false;
      this.playing = false;
      this.rtspSession = '';
    })
    .finally(this.destroy.bind(this))
    .finally(() => {
      this.trigger('stop');
    });
};

Liveview.prototype.destroy = function destroy() {
  this.removePlayer();
  this.removeAudio(this.audioContext, this.gainNode);
  this.videoTimeMapNotify = {};
  this.prevNotify = null;
  this.player = null;
  if (this.mediaSource) {
    this.mediaSource.removeEventListener('sourceopen', this.onSourceOpen);
  }
  this.mediaSource = null;
  if (this.sourceBuffer) {
    this.sourceBuffer.removeEventListener('updateend', this.onUpdateend);
  }
  this.sourceBuffer = null;
  this.queue = null;
  this.onSourceOpened = null;
  this.onUpdateend = null;

  this.audioContext = null;
  this.gainNode = null;
  this.audioQueue = null;

  this.polygons = null;
  this.rectangles = null;

  if (this.ssmux) {
    this.releaseSsmux(this.ssmux);
    this.ssmux = null;
  }

  this.frameCount = 0;
  this.fps = 0;
};

Liveview.prototype.setMute = function setMute() {
  this.mute = true;
  this.resumeAudio();

  if (!this.gainNode || this.speedUp) { return; }
  this.gainNode.gain.value = 0;
};

Liveview.prototype.unmute = function unmute() {
  this.mute = false;

  this.resumeAudio();

  if (!this.gainNode || this.speedUp) { return; }

  this.gainNode.gain.value = this.volume;
};

Liveview.prototype.setVolume = function setVolume(value) {
  this.mute = false;
  this.volume = value;

  this.resumeAudio();

  if (!this.gainNode || this.speedUp) { return; }

  this.gainNode.gain.value = value;
};

Liveview.prototype.setPlayerTimeout = function setPlayerTimeout() {
  this.clearPlayerTimeout();

  this.packetTimeout = setTimeout(() => {
    console.error('Player Timeout');

    this.stop().finally(() => {
      this.trigger('error', new Error('Player Timeout'));
    });
  }, 10 * 1000);
};

Liveview.prototype.clearPlayerTimeout = function clearPlayerTimeout() {
  if (this.packetTimeout) {
    clearTimeout(this.packetTimeout);
  }

  this.packetTimeout = null;
};

Liveview.prototype.checkShouldSkipTimeUpdate = function checkShouldSkipTimeUpdate() {
  return !this.player;
};

Liveview.prototype.switchAudio = function switchAudio() {
  if (this.player.playbackRate !== 1) { return; }
  // remove current audiiContext and create a new one
  this.removeAudio(this.audioContext, this.gainNode);
  this.createAudio(this.volume, this.mute)
    .then(([audioContext, gainNode]) => {
      this.audioContext = audioContext;
      this.gainNode = gainNode;
    })
    .then(() => {
      // append audio packet from 'backupAudioPackets'
      this.backupAudioPackets.forEach(({
        packet, sampleRate, duration, info
      }) => {
        if (!info) { return; }

        this.processAudio.call(this, packet, sampleRate, duration, info);
      });
    })
    .then(() => this.initAudio())
    .then(() => {
      // filter all passed audio packets
      const index = this.audioQueue.findIndex(({ info }) => this.audioContextStart + info.timestamp.audio >= 0);
      this.audioQueue = this.audioQueue.slice(index);
      // force process audio packets in queue
      return this.processAudioQueue(this.audioQueue, true)
        .then(this.playAudioPacket.bind(this));
    })
    .then(() => {
      if (this.isPause) {
        this.audioContext.suspend();
      }
    })
    .catch((err) => console.error('switchAudio err', err));
};

Liveview.prototype.pause = function pause() {};

Liveview.prototype.nextFrame = function nextFrame() {};

Liveview.prototype.setPlaybackRate = function setPlaybackRate() {};

export default MicroEvent.mixin(Liveview);
