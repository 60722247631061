import MicroEvent from '@vivotek/lib-utility/microevent';
import PLUGINFREE from '../scripts/constants/pluginfree'
/**
* The raw image decoder
*
* @constructor
*/
var Decoder = function({worker=PLUGINFREE.WORKER_265_SRC}={}) {
    this.ready_callback = null;
    this.image_callback = null;

    this.queueLimit = Decoder.THRESHOLD;
    this.worker_src = worker;

    this.prevIsIFrame = false;
    this.createdWokers = {};

    let readSource = this.loadDecoderSource();
    readSource.then(() => {
        this.createWorker().then(res => {
            this.workers = [res];
            this.ready_callback();
        });
    });
};

Decoder.THRESHOLD = 33;

Decoder.prototype.loadDecoderSource = function() {
    return new Promise(resolve => {
        if(typeof window.H265DECODER_PATH === 'undefined') {
            return resolve();
        }
        var self = this;
        var loadSource = new XMLHttpRequest();
        loadSource.open("GET", window.H265DECODER_PATH+".js", false);
        loadSource.onreadystatechange = function ()
        {
            if(loadSource.readyState === XMLHttpRequest.DONE && loadSource.status === 200)
            {
                let reg = /(var wasmBinaryFile=").+(.wasm")/;
                let content = loadSource.responseText.replace(reg, 'var wasmBinaryFile="' + window.H265DECODER_PATH.toString() + '.wasm"');
                self.worker_src = URL.createObjectURL(new Blob([content], {type: 'text/javascript'}));
                return resolve();
            }
        }
        loadSource.send();
    });
};

Decoder.prototype.createWorker = function() {
    return new Promise(resolve => {
        let timeout;
        const workerdecoder = new Worker(this.worker_src);
        const id = Math.random().toString(36).substr(-8);

        MicroEvent.mixin(workerdecoder);

        workerdecoder.id = id;
        workerdecoder.postMessage({"funcName":"InitialDecoder"});
        workerdecoder.addEventListener('message', (e) => {
            const data = e.data;
            const cmd = data["cmd"];

            if (cmd === 'decoder_ready') {
                if (timeout) {
                    clearTimeout(timeout);
                    timeout = null;
                }
                resolve(workerdecoder);

                this.createdWokers[id] = workerdecoder;
            }

            workerdecoder.trigger(cmd, data.data);
        }, false);

        workerdecoder.run_decode = setInterval(function(){
            workerdecoder.postMessage({"funcName":"Decode"});
        }, 0);

        workerdecoder.buffered = 0;
        workerdecoder.pushData = (data) => {
            workerdecoder.postMessage({
                "funcName": 'PushData',
                "data": data
            });
            workerdecoder.buffered++;
        };
        workerdecoder.onImage = (data) => {
            this.image_callback(data);
            workerdecoder.buffered--;

            let index = this.workers.indexOf(workerdecoder);

            if (index <= 1 || workerdecoder.buffered > 1) return;

            for (let i = this.workers.length - 1; i >= index; i--) {
                const worker = this.workers[i];

                this.workers.splice(i, 1);

                if (!worker) continue;

                this.removeWorker(worker);
            }
        };
        workerdecoder.on('image', workerdecoder.onImage);

        timeout = setTimeout(() => {
            this.removeWorker(workerdecoder);
            resolve(this.createWorker());
        }, 2000);
    });
};

Decoder.prototype.removeWorker = function (worker) {
    clearInterval(worker.run_decode);
    worker.off('image', worker.onImage);
    worker.postMessage({"funcName":"ReleaseDecoder"});
    worker.terminate();
    this.dropped_callback();

    delete this.createdWokers[worker.id];
};

/**
 * @expose
 */
Decoder.prototype.free = function() {
    this.workers.filter(worker => worker)
                .forEach(worker => {
        clearInterval(worker.run_decode);
        worker.off('image', worker.onImage);
        worker.postMessage({"funcName":"ReleaseDecoder"});
        worker.terminate();

        delete this.createdWokers[worker.id];
    });
    this.workers = [];
    this.ready_callback = null;
    this.image_callback = null;
    this.dropped_callback = function(){};
    this.stopped = true;

    Object.keys(this.createdWokers)
          .map(id => this.createdWokers[id])
          .forEach(worker => this.removeWorker(worker));
};

/**
 * @expose
 */
Decoder.prototype.set_image_callback = function(callback) {
    this.image_callback = callback;
};

/**
 * @expose
 */
Decoder.prototype.set_ready_callback = function(callback) {
    this.ready_callback = callback;
};

Decoder.prototype.set_dropped_callback = function(callback) {
    this.dropped_callback = callback;
};

/**
 * @expose
 */
Decoder.prototype.push_data = function(data, isIframe=false) {
    if (!this.workers || this.stopped) return;

    if (isIframe && !this.prevIsIFrame) {
        // for 1080p or higher
        if ((!this.workers[1] || this.workers[1].buffered > 1)
            && this.workers[0]) {
            this.workers.unshift(undefined);
            this.createWorker()
                .then(worker => {
                    if (this.stopped) this.removeWorker(worker);
                    else this.workers[0] = worker;
                });
            if (this.workers.length === 5) {
                const last = this.workers[4];

                this.workers.splice(4, 1);

                if (last) this.removeWorker(last);
            }

            const activeWorkers = this.workers.filter(worker => worker)
                                              .map(worker => worker.id);
            Object.keys(this.createdWokers)
                  .filter(id => !activeWorkers.includes(id))
                  .map(id => this.createdWokers[id])
                  .forEach(worker => this.removeWorker(worker));
        }
    }

    if (this.workers[1]) this.workers[1].pushData(data);

    this.prevIsIFrame = isIframe;
};

export default MicroEvent.mixin(Decoder);
