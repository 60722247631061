import MicroEvent from '@vivotek/lib-utility/microevent';
import { saveAs } from '../libs/FileSaver';
import Cassiae from './cassiae';

function Lutein(options) {
  let gl;

  const self = this;
  let lutein = [];

  let mouseDown = false;
  const mounttype = 1;

  function initGL(canvas) {
    try {
      gl = canvas.getContext('webgl', { preserveDrawingBuffer: true });
      gl.viewportWidth = canvas.width;
      gl.viewportHeight = canvas.height;
    } catch (_) {
      try {
        gl = canvas.getContext('experimental-webgl', { preserveDrawingBuffer: true });
        gl.viewportWidth = canvas.width;
        gl.viewportHeight = canvas.height;
      } catch (e) {
        console.warn('initGL failed, error', e);
      }
    }
    if (!gl) {
      // eslint-disable-next-line no-alert
      alert('Could not initialise WebGL, sorry :-(\nPlease to "http://get.webgl.org" get one');
      /* if("click ok to continue")
        window.location = "http://get.webgl.org"; */
    }
  }

  /* ======= Creating a canvas ========= */
  const canvas = options.canvas || document.createElement('canvas');
  canvas.style.background = '#000000';
  this.canvas = canvas;

  initGL(canvas);

  // Module.setPTZ(0, 0, 0, 0);
  gl.viewportWidth = canvas.width;
  gl.viewportHeight = canvas.height;
  gl.enable(gl.DEPTH_TEST);
  gl.clearColor(0.0, 0.0, 0.0, 1.0);
  // eslint-disable-next-line no-bitwise
  gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

  // lutein
  lutein = new Cassiae({ mounttype }, tick);
  lutein.setGL(gl, canvas);
  lutein.setPresentMode(0); // 0 : 1O, 1: 1R, 2: 1P
  lutein.enable1OVerification(true);

  // mouse event
  let isDraggable = options.draggable || (options.draggable === undefined);
  canvas.onmousedown = handleMouseDown;
  canvas.onmouseup = handleMouseUp;
  canvas.onmousemove = handleMouseMove;
  canvas.onwheel = handleMouseScroll;

  // setInterval(tick, 15);
  const requestAnimationFrame = (function requestAnimationFrame() {
    return function setTimeout(callback) {
      return window.setTimeout(callback, 1000 / 60);
    };
  }());

  const cancelAnimationFrame = (function cancelAnimationFrame() {
    return function clearTimeout(timeout) {
      window.clearTimeout(timeout);
    };
  }());

  const requestIdleCallback = (function requestIdleCallback() {
    return window.requestIdleCallback
           || function requestCallback(callback) {
             return window.setTimeout(callback, 1000 / 30);
           };
  }());

  const cancelIdleCallback = (function cancelIdleCallback() {
    return window.cancelIdleCallback
           || function cancelCallback(timeout) {
             window.clearTimeout(timeout);
           };
  }());
  let requestFrame; let cancelFrame; let
    requestId;

  this.lazyMode = options.lazyMode !== undefined ? !!options.lazyMode : true;

  const motionWindowExpire = 500;
  let polygons = [];
  let dewarpable = false;

  const { video, cover } = options;
  let coveredImg = cover ?? document.createElement('canvas');

  const playNextFrame = (callback) => {
    requestFrame = this.lazyMode ? requestIdleCallback : requestAnimationFrame;
    cancelFrame = this.lazyMode ? cancelIdleCallback : cancelAnimationFrame;

    requestId = requestFrame(callback);
  };

  const start = function start() {
    onResize();
    if (requestId) {
      stop();
    }

    playNextFrame(tick);
  };
  const stop = function stop() {
    if (!requestId) {
      return;
    }

    cancelFrame(requestId);
    requestId = null;
  };
  const onResize = function onResize() {
    resize(canvas.clientWidth, canvas.clientHeight);
  };

  video.addEventListener('play', start);

  if (!video.paused) {
    start();
  }

  window.addEventListener('resize', onResize);

  let resolutionWidth; let
    resolutionHeight;

  // ----------------------------------------------------------------------------------
  function tick() {
    const now = new Date();

    lutein.clearLines();

    // drow lines
    polygons = polygons.filter((polygon) => polygon.expire >= now);

    polygons.forEach((polygon) => {
      const { coordinates } = polygon;
      const { length } = coordinates;
      let p1X; let p1Y; let p2X; let
        p2Y;

      function smooth(val) {
        // addLine receive value in -1 ~ 1
        return val * 2 - 1;
      }

      for (let i = 0; i + 1 < length; i += 2) {
        p1X = smooth(coordinates[i]);
        p1Y = smooth(1 - coordinates[i + 1]);
        p2X = smooth(coordinates[(i + 2) % length]);
        p2Y = smooth(1 - coordinates[(i + 3) % length]);
        lutein.addLine(p1X, p1Y, p2X, p2Y);
      }
    });

    if (coveredImg) {
      lutein.renderVideoAndCover(video, coveredImg);
    } else {
      lutein.renderVideo(video);
    }

    const isValid = lutein.isValid();

    if (isValid !== dewarpable) {
      dewarpable = isValid;
      self.trigger('valid', dewarpable);
    }
    playNextFrame(tick);
  }

  function resize(w, h) {
    canvas.width = w;
    canvas.height = h;

    gl.viewportWidth = canvas.width;
    gl.viewportHeight = canvas.height;

    lutein.setROI(0, 0, w, h);
  }

  // ----------------------------------------------------------------------------------

  // ePTZ control
  function handleMouseDown(event) {
    if (!isDraggable) {
      return;
    }

    mouseDown = true;

    lutein.setMouseDown(event);

    // Register mouseup on `window` to catch mouseup event off canvas,
    // and even out of the browser window.
    window.addEventListener('mouseup', handleMouseUpOnWindow);

    // prevent scrolling and selecting other elements
    event.preventDefault();
  }

  function handleMouseUpOnWindow(event) {
    handleMouseUp(event);

    window.removeEventListener('mouseup', handleMouseUpOnWindow);
  }

  function handleMouseUp(event) {
    if (!isDraggable) {
      return;
    }

    mouseDown = false;
    lutein.setMouseUp(event);
  }

  function handleMouseMove(event) {
    if (!mouseDown) {
      return;
    }
    lutein.setMouseMove(event);
    self.trigger('preset', self.getPresetInfo());
  }

  function handleMouseScroll(event) {
    if (!isDraggable) { return false; }

    const delta = event.deltaY < 0 ? 0.1 : -0.1;

    lutein.setPTZ(0, 0, 0, delta);

    self.trigger('preset', self.getPresetInfo());

    event.preventDefault();
    return false; // TODO check if someine use this return value
  }

  // ----------------------------------------------------------------------------------

  this.changeDewarpType = (type) => {
    lutein.setPresentMode(type);

    if (!type) {
      return;
    }

    this.trigger('preset', self.getPresetInfo());
  };

  this.getPresetInfo = () => lutein.getPresetInfo();

  this.setPresetInfo = (presetInfo) => {
    lutein.setPresetInfo(presetInfo);
  };

  this.changeMountType = (type) => {
    lutein.setMountType(type);
  };

  this.destroy = () => {
    video.removeEventListener('play', start);

    this.reset();
    if (!options.canvas) {
      canvas.remove();
    }
    lutein.release();

    window.removeEventListener('resize', onResize);
    window.removeEventListener('mouseup', handleMouseUpOnWindow);
  };

  this.addPolygon = (polyArr) => {
    const now = new Date();

    polygons.push({
      coordinates: polyArr,
      expire: new Date(now.getTime() + motionWindowExpire)
    });
  };

  this.setResolution = (width, height) => {
    if (resolutionWidth === width && resolutionHeight === height) {
      return;
    }

    resolutionWidth = width;
    resolutionHeight = height;

    lutein.setCenterRadius(resolutionWidth / 2, resolutionHeight / 2, resolutionHeight / 2);
  };

  this.snapshot = (isOrigImg) => {
    const canvasEl = document.createElement('canvas');
    const canvasTx = canvasEl.getContext('2d');
    let source;

    if (isOrigImg) {
      source = video;
      // set image's width/height by streaming's resolution
      canvasEl.width = resolutionWidth;
      canvasEl.height = resolutionHeight;
    } else {
      source = canvas;
      // set image's width/height by canvas element's clientWidth/clientHeight
      canvasEl.width = canvas.clientWidth;
      canvasEl.height = canvas.clientHeight;
    }

    canvasTx.drawImage(source, 0, 0, canvasEl.width, canvasEl.height);
    canvasEl.toBlob((blob) => {
      saveAs(blob, 'screenshot.png', 'image/png');
    });
  };

  this.setSrcROI = (cx, cy, r) => {
    if (!resolutionWidth || !resolutionHeight) {
      return;
    }
    // Prevent dewarp error caused by wrong fisheye info (raduis, center) when play playback
    // video with audio in NVR version under 2.2.x.x
    // Check raduis and center are between the reasonable range
    if (cx > video.videoWidth || cx <= 0 || cy > video.videoHeight || cy <= 0
      || r > video.videoWidth || r > video.videoHeight || r <= 0) {
        return;
      }

    lutein.setCenterRadius(cx, cy, r);
  };

  this.resize = (w, h) => {
    resize(w, h);
  };

  this.reset = () => {
    stop();
    gl.clear(gl.COLOR_BUFFER_BIT);
  };

  this.setDraggable = (draggable = true) => {
    isDraggable = draggable;
  };

  this.turnOffCover = () => {
    coveredImg = null;
  };

  this.turnOnCover = () => {
    coveredImg = cover ?? document.createElement('canvas');
  };
}

export default MicroEvent.mixin(Lutein);
