import MicroEvent from '@vivotek/lib-utility/microevent';
import AbstractPlayer from './AbstractPlayer';

class VideoPlayer extends AbstractPlayer {
  constructor(options) {
    super(options);

    const video = MicroEvent.mixin(document.createElement('video'));

    video.muted = true;
    video.autoplay = false;
    video.addEventListener('play', () => {
      this.onPlay();
      video.trigger('play');
    });
    video.addEventListener('timeupdate', () => {
      this.onTimeupdate();
      video.trigger('timeupdate');
    });
    video.addEventListener('pause', () => {
      this.onPause();
      video.trigger('pause');
    });
    video.addEventListener('loadeddata', () => {
      if (video.readyState === 4 && !window.document.hasFocus()) {
        video.play();
      }
    });

    this.player = video;
    this.video = video;
    this.queue = [];
    this.onSourceOpen = null;
    this.onUpdateend = null;
    this.sourceBufferStart = 0;
    this.KEEP_PASSED_LENGTH = 20;
    this.SOURCEBUFFER_LOWER_LENGTH = 0.8;
    this.SOURCEBUFFER_UPPER_LENGTH = 1.8;

    this.createMediasource()
      .then(([mediaSource, sourceBuffer]) => this.onInited(mediaSource, sourceBuffer));
  }

  get canAppendBuffer() {
    const { mediaSource, sourceBuffer } = this;

    return mediaSource?.readyState === 'open' && !sourceBuffer.updating;
  }

  get bufferedLength() {
    const { video, bufferedEnd } = this;
    try {
      return bufferedEnd - video.currentTime;
    } catch (err) {
      console.warn(err);

      return 0;
    }
  }

  get bufferedEnd() {
    const { sourceBuffer } = this;
    try {
      return sourceBuffer.buffered.end(0);
    } catch (err) {
      console.warn(err);

      return 0;
    }
  }

  get canAppendBuffer() {
    const { mediaSource, sourceBuffer } = this;
    return mediaSource?.readyState === 'open' && !sourceBuffer.updating;
  }

  createMediasource() {
    const { video, queue } = this;
    const mediaSource = new MediaSource();

    return new Promise((resolve) => {
      this.onSourceOpen = () => {
        mediaSource.duration = Infinity;

        const sourceBuffer = mediaSource.addSourceBuffer('video/mp4; codecs="avc1.42E01E"');

        this.onUpdateend = this.onSourceBufferUpdated.bind(this, video, sourceBuffer, queue);

        sourceBuffer.addEventListener('updateend', this.onUpdateend);

        resolve([mediaSource, sourceBuffer]);
      };
      mediaSource.addEventListener('sourceopen', this.onSourceOpen);

      video.src = URL.createObjectURL(mediaSource);
    });
  }

  onSourceBufferUpdated() {
    const {
      sourceBuffer, canAppendBuffer, queue,
      playing, bufferedEnd,
      video, playbackReady, playbackOffset,
    } = this;

    queue.shift();

    const play = () => this.video.play().catch((err) => {
      this.trigger('error', err);
    });

    if (!playing) {
      if (playbackReady && bufferedEnd >= playbackOffset) {
        video.currentTime = playbackOffset;
        play();
      }
    }

    if (queue.length && canAppendBuffer) {
      const packet = queue[0];

      sourceBuffer.appendBuffer(packet);
    }
  }

  onInited(mediaSource, sourceBuffer) {
    this.mediaSource = mediaSource;
    this.sourceBuffer = sourceBuffer;
    this.isInited = true;

    const { queue } = this;

    if (queue.length > 0) {
      const packet = queue[0];

      sourceBuffer.appendBuffer(packet);
    }
  }

  onTimeupdate() {
    if (this.playing) {
      super.onTimeupdate();
      this.adjustPlaybackRate();
      this.cutSourceBufferHead();
    }
  }

  adjustPlaybackRate() {
    const { player, bufferedLength, SOURCEBUFFER_UPPER_LENGTH, SOURCEBUFFER_LOWER_LENGTH } = this;
    // check buffer length and speed up if queued more.
    // check buffer's length
    if (bufferedLength > SOURCEBUFFER_UPPER_LENGTH
      && player.playbackRate === 1) {
      console.warn('speed up playbackRate', 1.5);

      player.playbackRate = 1.5;
    } else if (bufferedLength < SOURCEBUFFER_LOWER_LENGTH
      && player.playbackRate !== 1) {
      console.warn('playbackRate', 1);

      player.playbackRate = 1;
    }
  }

  cutSourceBufferHead() {
    const { sourceBufferStart, video, sourceBuffer } = this;
    const bufLength = this.KEEP_PASSED_LENGTH;

    try {
      if (video.currentTime - sourceBufferStart > bufLength * 2 && !sourceBuffer.updating) {
        sourceBuffer.remove(sourceBufferStart, sourceBufferStart + bufLength);

        this.sourceBufferStart += bufLength;
      }
    } catch (err) {
      console.error(err);
    }
  }

  appendPacket({ packet, info }) {
    super.appendPacket({ packet, info });

    const { canAppendBuffer, sourceBuffer, queue } = this;

    if (queue.length === 0 && canAppendBuffer) {
      sourceBuffer.appendBuffer(packet);
    }

    queue.push(packet);
  }

  switch() {
    super.switch();
    this.player.pause();
    this.player.currentTime += this.bufferedLength;
  }

  stop() {
    this.player.pause();
    super.stop();
  }

  destroy() {
    const { video } = this;
    const { src } = video;

    video.pause();
    video.src = '';
    video.load();
    URL.revokeObjectURL(src);

    if (this.mediaSource) {
      this.mediaSource.removeEventListener('sourceopen', this.onSourceOpen);
      this.mediaSource = null;
      this.onSourceOpen = null;
    }
    if (this.sourceBuffer) {
      this.sourceBuffer.removeEventListener('updateend', this.onUpdateend);
      this.sourceBuffer = null;
      this.onUpdateend = null;
    }
    this.sourceBufferStart = 0;
  }
}

export default MicroEvent.mixin(VideoPlayer);
