import { ResizeObserver as Polyfill } from '@juggle/resize-observer';
import { saveAs } from '../libs/FileSaver';
import MicroEvent from '@vivotek/lib-utility/microevent';

const ResizeObserver = window.ResizeObserver || Polyfill;

const requestAnimFrame = (function requestAnimFrame() {
  return window.requestAnimationFrame
         || window.webkitRequestAnimationFrame
         || window.mozRequestAnimationFrame
         || window.oRequestAnimationFrame
         || window.msRequestAnimationFrame
         || function requestAnimationFrame(callback) {
           return window.setTimeout(callback, 1000 / 30);
         };
}());

const cancelAnimFrame = (function cancelAnimFrame() {
  return window.cancelAnimationFrame
         || window.mozCancelAnimationFrame
         || function cancelAnimationFrame(timeout) {
           window.clearTimeout(timeout);
         };
}());

const requestIdleCallback = (function requestIdleCallback() {
  return window.requestIdleCallback
         || function requestCallback(callback) {
           return window.setTimeout(callback, 1000 / 30);
         };
}());

const cancelIdleCallback = (function cancelIdleCallback() {
  return window.cancelIdleCallback
         || function cancelCallback(timeout) {
           window.clearTimeout(timeout);
         };
}());

let requestFrame; let
  cancelFrame;

class Viewcell {
  constructor({
    video = null,
    canvas = null,
    cover = document.createElement('canvas'),
    stretch = true,
    lazyMode = true,
    draggable = false,
  } = {}) {
    if (!video) {
      return;
    }

    this.video = video;
    this.origin = document.createElement('canvas');
    this.originCtx = this.origin.getContext('2d');
    this.canvasCreated = canvas != null;
    this.canvas = canvas || document.createElement('canvas');
    this.context = this.canvas.getContext('2d');
    this.cover = cover;
    this.coveredImg = cover;

    this.scale = 100;
    this.defaultScaleDelta = 10;
    this.maxScale = 500;
    this.minScale = 100;

    this.frameX = 0;
    this.frameY = 0;
    this.frameWidth = 0;
    this.frameHeight = 0;

    this.stretch = stretch;
    // fix resolution 3840 * 2160
    this.canvas.width = 3840;
    this.canvas.height = 2160;

    this.originCtx.fillRect(0, 0, this.width, this.height);
    this.context.fillRect(0, 0, this.width, this.height);

    this.polygons = [];
    this.rectangles = [];
    this.motionWindowExpire = 250;
    this.motionWindowLineWidth = 4;

    this.screenshotType = 'png';
    this.screenshotName = 'screenshot';

    this.isMoveWindowPOS = false;
    this.pipInfo = {
      x: 0, y: 0, width: 1, height: 1
    };

    this.lazyMode = lazyMode;
    this.nextFrame = null;

    this.onVideoPlay = () => {
      if (this.pause) {
        delete this.pause;
      } else {
        this.initFrame();
      }

      if (this.hasNextFrame()) {
        return;
      }

      this.playNextFrame(this.playFrame.bind(this));
    };
    this.onVideoPause = () => {
      this.pause = true;
    };

    this.video.addEventListener('play', this.onVideoPlay);
    this.video.addEventListener('pause', this.onVideoPause);

    if (!this.video.paused) {
      this.onVideoPlay();
    }

    this.resizeObs = new ResizeObserver(([entry]) => {
      const { width, height } = entry.contentRect;

      this.setWidthAndHeight(width, height);
      this.initFrame();

      const { pipInfo } = this;
      this.moveWindowPOS(pipInfo.x, pipInfo.y, pipInfo.width, this.pipInfo.height);
    });
    this.resizeObs.observe(this.canvas);

    // ePTZ control
    this.handleMouseDown = (evt) => {
      if (!this.isDraggable) {
        return;
      }

      this.dragStart = {
        x: evt.offsetX,
        y: evt.offsetY
      };
      this.dragging = {
        x: evt.offsetX,
        y: evt.offsetY
      };

      // Register mouseup on `window` to catch mouseup event off canvas,
      // and even out of the browser window.
      window.addEventListener('mouseup', this.handleMouseUpOnWindow);

      // prevent scrolling and selecting other elements
      event.preventDefault();
    };
    this.handleMouseUpOnWindow = (evt) => {
      this.handleMouseUp(evt);

      window.removeEventListener('mouseup', this.handleMouseUpOnWindow);
    };
    this.handleMouseMove = (evt) => {
      if (!this.isDraggable || !this.dragStart) {
        return;
      }

      this.dragging = {
        x: evt.offsetX,
        y: evt.offsetY
      };

      const now = Date.now();

      if (this.dragEventExpired && this.dragEventExpired > now) {
        return;
      }

      const { width, height } = this;
      const deltaX = evt.offsetX - this.dragStart.x;
      const deltaY = this.dragStart.y - evt.offsetY;
      const deltaDirect = Math.sqrt(deltaX ** 2 + deltaY ** 2);
      const radius = Math.max(width, height);
      const speed = Math.min(deltaDirect / radius, 1);
      const x = deltaX / width;
      const y = deltaY / height;

      this.trigger('drag', { x, y, speed });
      this.dragEventExpired = now + 100;
    };
    this.handleMouseUp = (/* evt */) => {
      if (!this.dragStart) {
        return;
      }

      this.dragStart = null;
      this.dragging = null;
      this.trigger('drag', { x: 0, y: 0, speed: 0 });
    };

    // set canvas mouse event handler
    this.canvas.addEventListener('mousedown', this.handleMouseDown);
    this.canvas.addEventListener('mousemove', this.handleMouseMove);
    this.canvas.addEventListener('mouseup', this.handleMouseUp);

    this.isDraggable = draggable;
    this.dragStart = null;
    this.dragging = null;
    this.dragEventExpired = null;
  }

  setWidthAndHeight(width = 1920, height = 1080) {
    this.width = width;
    this.height = height;
    this.origin.width = width;
    this.origin.height = height;
  }

  reset() {
    this.stopNextFrame();
    this.originCtx.fillRect(0, 0, this.width, this.height);
    this.context.fillRect(0, 0, this.width, this.height);
  }

  destroy() {
    this.video.removeEventListener('play', this.onVideoPlay);
    this.video.removeEventListener('pause', this.onVideoPause);

    this.stopNextFrame();

    const { canvas } = this;

    canvas.removeEventListener('mousedown', this.handleMouseDown);
    canvas.removeEventListener('mousemove', this.handleMouseMove);
    canvas.removeEventListener('mouseup', this.handleMouseUp);
    window.removeEventListener('mouseup', this.handleMouseUpOnWindow);

    if (!this.canvasCreated && canvas.parentNode) {
      canvas.parentNode.removeChild(canvas);
    }

    delete this.canvas;

    this.resizeObs.disconnect();
  }

  zoomIn(value) {
    let zoomValue = isNaN(value) ? this.defaultScaleDelta : Number(value);
    zoomValue = (this.scale + zoomValue) > this.maxScale ? 0 : Number(zoomValue);

    const { video } = this;
    const videoRatio = video.videoWidth / video.videoHeight;
    const canvasRatio = this.width / this.height;
    const directX = ((this.width / 2 - this.frameX)
                    * (this.scale + zoomValue)) / this.scale;
    const directY = ((this.height / 2 - this.frameY)
                    * (this.scale + zoomValue)) / this.scale;
    let width;
    let height;

    this.scale += zoomValue;
    this.frameX = this.width / 2 - directX;
    this.frameY = this.height / 2 - directY;

    if (this.stretch) {
      width = this.width;
      height = this.height;
    } else if (videoRatio >= canvasRatio) {
      width = this.width;
      height = width / videoRatio;
    } else {
      height = this.height;
      width = height * videoRatio;
    }

    const scaleRatio = this.scale / this.minScale;
    this.frameWidth = width * scaleRatio;
    this.frameHeight = height * scaleRatio;
  }

  zoomOut(value) {
    let zoomValue = isNaN(value) ? this.defaultScaleDelta : Number(value);
    zoomValue = (this.scale - zoomValue) < this.minScale ? 0 : Number(zoomValue);

    const { video } = this;
    const videoRatio = video.videoWidth / video.videoHeight;
    const canvasRatio = this.width / this.height;
    const directX = ((this.width / 2 - this.frameX)
                    * (this.scale - zoomValue)) / this.scale;
    const directY = ((this.height / 2 - this.frameY)
                    * (this.scale - zoomValue)) / this.scale;
    let width;
    let height;

    this.scale -= zoomValue;
    this.frameX = this.width / 2 - directX;
    this.frameY = this.height / 2 - directY;

    const scaleRatio = this.scale / this.minScale;

    if (this.stretch) {
      width = this.width;
      height = this.height;

      this.frameX = Math.max(Math.min(this.frameX, 0), width - width * scaleRatio);
      this.frameY = Math.max(Math.min(this.frameY, 0), height - height * scaleRatio);
    } else if (videoRatio >= canvasRatio) {
      width = this.width;
      height = width / videoRatio;

      this.frameX = Math.max(Math.min(this.frameX, 0), width - width * scaleRatio);
      this.frameY = Math.max(Math.min(this.frameY, (this.height - height) / 2),
        (this.height - height) / 2 + height - height * scaleRatio);
    } else {
      height = this.height;
      width = height * videoRatio;

      this.frameY = Math.max(Math.min(this.frameY, 0), height - height * scaleRatio);
      this.frameX = Math.max(Math.min(this.frameX, (this.width - width) / 2),
        (this.width - width) / 2 + width - width * scaleRatio);
    }

    this.frameWidth = width * scaleRatio;
    this.frameHeight = height * scaleRatio;
  }

  movePIP(topRatio, leftRatio) {
    const scaleRatio = this.scale / this.minScale;
    const { video } = this;
    const videoRatio = video.videoWidth / video.videoHeight;
    const canvasRatio = this.width / this.height;
    let height;
    let width;

    if (this.stretch) {
      width = this.width;
      height = this.height;

      this.frameX = Math.min(Math.max(-leftRatio * width * (scaleRatio), width - width * scaleRatio), 0);
      this.frameY = Math.min(Math.max(-topRatio * height * (scaleRatio), height - height * scaleRatio), 0);
    } else if (videoRatio >= canvasRatio) {
      height = (this.width / videoRatio) * scaleRatio;
      width = this.width;

      this.frameX = Math.min(Math.max(-leftRatio * width * (scaleRatio), width - width * scaleRatio), 0);
      this.frameY = height > this.height
        ? Math.min(Math.max(-topRatio * width * (scaleRatio), this.height - height), 0)
        : this.frameY;
    } else {
      width = (this.height * videoRatio) * scaleRatio;
      height = this.height;

      this.frameY = Math.min(Math.max(-topRatio * width * (scaleRatio), height - height * scaleRatio), 0);
      this.frameX = width > this.width
        ? Math.min(Math.max(-leftRatio * width * (scaleRatio), this.width - width), 0)
        : this.frameX;
    }
  }

  initZoom() {
    this.scale = 100;
    this.isMoveWindowPOS = false;
    this.initFrame();
  }

  moveWindowPOS(x, y, width, height) {
    const { video } = this;
    const videoRatio = video.videoWidth / video.videoHeight;
    const canvasRatio = this.width / this.height;

    this.frameWidth = this.width / width;
    this.frameHeight = this.height / height;
    this.frameX = this.frameWidth * x * -1;
    this.frameY = this.frameHeight * y * -1;

    if (!this.stretch) {
      if (videoRatio >= canvasRatio) {
        const maskY = (this.height - this.width / videoRatio) / 2;

        this.frameY += maskY;
        this.frameHeight -= maskY * 2;
        this.maskY = maskY;
        delete this.maskX;
      } else {
        const maskX = (this.width - this.height * videoRatio) / 2;

        this.frameX += maskX;
        this.frameWidth -= maskX * 2;
        this.maskX = maskX;
        delete this.maskY;
      }
    } else {
      delete this.maskX;
      delete this.maskY;
    }

    this.isMoveWindowPOS = true;
    this.pipInfo = {
      x, y, width, height
    };
  }

  closeWindowPOS() {
    delete this.maskX;
    delete this.maskY;

    this.isMoveWindowPOS = false;
  }

  setStretchMode() {
    this.stretch = true;
    this.isMoveWindowPOS = false;
    this.initZoom();
  }

  setFitRatioMode() {
    this.stretch = false;
    this.isMoveWindowPOS = false;
    this.initZoom();
  }

  initFrame(image) {
    const { video } = this;
    const videoRatio = video.videoWidth / video.videoHeight;
    const canvasRatio = this.width / this.height;
    const scaleRatio = (this.scale / this.minScale);
    let x;
    let y;
    let width;
    let height;

    if (this.stretch) {
      width = this.width;
      height = this.height;
      x = 0;
      y = 0;
    } else if (videoRatio >= canvasRatio) {
      width = this.width;
      height = width / videoRatio;
      x = 0;
      y = (this.height - height) / 2;
    } else {
      height = this.height;
      width = height * videoRatio;
      y = 0;
      x = (this.width - width) / 2;
    }

    if (!this.isMoveWindowPOS) {
      this.frameX = x + ((width * (1 - scaleRatio)) / 2);
      this.frameY = y + ((height * (1 - scaleRatio)) / 2);
      this.frameWidth = width * scaleRatio;
      this.frameHeight = height * scaleRatio;
    }

    if (image) {
      this.context.drawImage(image, 0, 0, image.width, image.height);
    }
  }

  playFrame() {
    const { video } = this;

    if (!this.pause) {
      this.originCtx.drawImage(video, 0, 0, this.width, this.height);

      if (this.coveredImg) {
        this.originCtx.drawImage(this.coveredImg, 0, 0, this.width, this.height);
      }

      this.drowRectangle();
      this.drowPolygon();
    }

    const { width, height } = this.canvas;
    const frameX = (this.frameX / this.width) * width;
    const frameY = (this.frameY / this.height) * height;
    const frameWidth = (this.frameWidth / this.width) * width;
    const frameHeight = (this.frameHeight / this.height) * height;

    if (this.width && this.height) {
      this.context.fillRect(0, 0, width, height);
      this.context.drawImage(this.origin, frameX, frameY, frameWidth, frameHeight);
    }

    this.context.fillStyle = '#000000';

    if (this.maskX) {
      const maskX = (this.maskX / this.width) * width;

      this.context.fillRect(0, 0, maskX, height);
      this.context.fillRect(width - maskX, 0, maskX, height);
    } else if (this.maskY) {
      const maskY = (this.maskY / this.height) * height;

      this.context.fillRect(0, 0, width, maskY);
      this.context.fillRect(0, height - maskY, width, maskY);
    }

    this.drowDragLine();
    this.playNextFrame(this.playFrame.bind(this));
  }

  playNextFrame(callback) {
    requestFrame = this.lazyMode ? requestIdleCallback : requestAnimFrame;
    cancelFrame = this.lazyMode ? cancelIdleCallback : cancelAnimFrame;

    this.nextFrame = requestFrame(callback);
  }

  hasNextFrame() {
    return !!this.nextFrame;
  }

  stopNextFrame() {
    if (!this.hasNextFrame()) {
      return;
    }

    cancelFrame(this.nextFrame);
    this.nextFrame = null;
  }

  addPolygon(polyArr) {
    this.polygons.push({
      coordinates: polyArr,
      expire: new Date(Date.now() + this.motionWindowExpire)
    });
  }

  drowPolygon() {
    const now = new Date();
    const context = this.originCtx;
    const lineWidth = this.motionWindowLineWidth;

    const { width, height } = this;

    this.polygons = this.polygons.filter((polygon) => polygon.expire >= now);

    this.polygons.forEach((polygon) => {
      const { coordinates } = polygon;
      let x; let y; let
        i;

      context.beginPath();
      context.moveTo(coordinates[0] * width, coordinates[1] * height);

      for (i = 2; i < coordinates.length; i += 2) {
        x = coordinates[i];
        y = coordinates[i + 1];

        if (x !== undefined && y !== undefined) {
          context.lineTo(x * width, y * height);
        }
      }

      context.closePath();
      context.strokeStyle = '#ff0000';
      context.lineWidth = lineWidth;
      context.stroke();
    });
  }

  drowDragLine() {
    if (!this.dragStart || !this.dragging || !this.width || !this.height) {
      return;
    }

    const ctx = this.context;
    const scaleWidthRatio = 3840 / this.width;
    const scaleHeightRatio = 2160 / this.height;
    const mouseStartX = this.dragStart.x * scaleWidthRatio;
    const mouseStartY = this.dragStart.y * scaleHeightRatio;
    const mouseCurrX = this.dragging.x * scaleWidthRatio;
    const mouseCurrY = this.dragging.y * scaleHeightRatio;
    const scaleRatio = scaleHeightRatio;

    ctx.globalAlpha = 0.5;
    ctx.setLineDash([10]);
    ctx.beginPath();
    ctx.moveTo(mouseStartX, mouseStartY);
    ctx.lineTo(mouseCurrX, mouseCurrY);
    ctx.closePath();
    ctx.lineWidth = 2 * scaleRatio;
    ctx.strokeStyle = '#FFFFFF';
    ctx.stroke();

    ctx.globalAlpha = 1;
    ctx.setLineDash([]);
    ctx.beginPath();
    ctx.arc(mouseStartX, mouseStartY, 7 * scaleRatio, 0, 2 * Math.PI);
    ctx.fillStyle = '#2986FF';
    ctx.fill();
    ctx.lineWidth = 2 * scaleRatio;
    ctx.strokeStyle = '#FFFFFF';
    ctx.stroke();

    ctx.beginPath();
    ctx.arc(mouseCurrX, mouseCurrY, 7 * scaleRatio, 0, 2 * Math.PI);
    ctx.fillStyle = '#2986FF';
    ctx.fill();
    ctx.strokeStyle = '#2986FF';
    ctx.stroke();
    ctx.beginPath();
    ctx.arc(mouseCurrX, mouseCurrY, 4.5 * scaleRatio, 0, 2 * Math.PI);
    ctx.fillStyle = '#2986FF';
    ctx.fill();
    ctx.lineWidth = 1.5 * scaleRatio;
    ctx.strokeStyle = '#FFFFFF';
    ctx.stroke();
  }

  addRectangle(x, y, width, height) {
    this.rectangles.push({
      x,
      y,
      width,
      height,
      expire: new Date(Date.now() + this.motionWindowExpire)
    });
  }

  drowRectangle() {
    const now = new Date();
    const context = this.originCtx;
    const lineWidth = this.motionWindowLineWidth;

    const { width, height } = this;

    this.rectangles = this.rectangles.filter((rectangle) => rectangle.expire >= now);

    this.rectangles.forEach((rectangle) => {
      context.strokeStyle = '#FF0000';
      context.lineWidth = lineWidth;
      context.strokeRect(
        rectangle.x * width,
        rectangle.y * height,
        rectangle.width * width,
        rectangle.height * height
      );
    });
  }

  moveFrame(x, y) {
    const { video } = this;
    const videoRatio = video.videoWidth / video.videoHeight;
    const canvasRatio = this.width / this.height;
    const scaleRatio = (this.scale / this.minScale);
    let width;
    let height;

    if (this.stretch) {
      this.frameX = Math.max(Math.min(this.frameX + x, 0), this.width * (1 - scaleRatio));
      this.frameY = Math.max(Math.min(this.frameY + y, 0), this.height * (1 - scaleRatio));
    } else if (videoRatio >= canvasRatio) {
      height = (this.width / videoRatio) * scaleRatio;
      this.frameX = Math.max(Math.min(this.frameX + x, 0), this.width * (1 - scaleRatio));
      this.frameY = height > this.height
        ? Math.max(Math.min(this.frameY + y, 0), this.height - height)
        : this.frameY;
    } else {
      width = (this.height * videoRatio) * scaleRatio;
      this.frameY = Math.max(Math.min(this.frameY + y, 0), this.height * (1 - scaleRatio));
      this.frameX = width > this.width
        ? Math.max(Math.min(this.frameX + x, 0), this.width - width)
        : this.frameX;
    }
  }

  getLeftRatio() {
    return -this.frameX / this.frameWidth;
  }

  getTopRatio() {
    return -this.frameY / this.frameHeight;
  }

  getBlob(isOrigImg, options = {}) {
    const canvas = document.createElement('canvas');
    const canvastx = canvas.getContext('2d');
    const screenshotType = `image/${options.type || this.screenshotType}`;
    let source;

    if (isOrigImg) {
      source = this.video;
      // set image's width/height by streaming's resolution
      canvas.width = this.video.videoWidth;
      canvas.height = this.video.videoHeight;
    } else {
      source = this.canvas;
      // set image's width/height by canvas element's clientWidth/clientHeight
      canvas.width = options.width || this.canvas.clientWidth;
      canvas.height = options.height || this.canvas.clientHeight;
    }

    return new Promise((resolve) => {
      canvastx.drawImage(source, 0, 0, canvas.width, canvas.height);
      canvas.toBlob(resolve, screenshotType);
    });
  }

  snapshot(isOrigImg, download = true) {
    const screenshotType = `image/${this.screenshotType}`;
    const screenshotName = `${this.screenshotName}.${this.screenshotType}`;

    return this.getBlob(isOrigImg).then((blob) => {
      if (download) {
        saveAs(blob, screenshotName, screenshotType);
      }
      return blob;
    });
  }

  setDraggable(draggable = true) {
    this.isDraggable = draggable;
  }

  turnOffCover() {
    this.coveredImg = null;
  }

  turnOnCover() {
    this.coveredImg = this.cover;
  }
}

export default MicroEvent.mixin(Viewcell);
