import RtspProtocol from '../utils/rtsp_protocol';
import MicroEvent from '@vivotek/lib-utility/microevent';

class RtspHandler {
  constructor(options) {
    const {
      username='',
      sessionId='',
      rtspChannel=null,
      url=''
    } = options;

    const channel = (rtspChannel instanceof WebSocket)
      ? new RtspProtocol(rtspChannel)
      : rtspChannel;

    this.rtspChannel = channel;
    this.url = url;
    this.username = username;
    this.sessionId = sessionId;
    this.status = 'TEARDOWN';
    this.session = '';
  }

  getAuthParams() {
    const { username, sessionId } = this;
    let params;

    if (username || sessionId) {
      params = {};

      if (username) {
        params.username = username;
      }
      if (sessionId) {
        // eslint-disable-next-line no-underscore-dangle
        params._SID_ = sessionId;
      }
    }

    return params;
  }

  checkStatusIsAllowed(allowStatus=[]) {
    return allowStatus.includes(this.status);
  }

  sendOptions() {
    if (!this.checkStatusIsAllowed(['TEARDOWN'])) {
      return Promise.reject();
    }

    const { rtspChannel, url } = this;

    return rtspChannel.sendOptions(url).then(() => {
      this.status = 'OPTIONS';
    });
  }

  sendDescribe() {
    if (!this.checkStatusIsAllowed(['OPTIONS'])) {
      return Promise.reject();
    }

    const { rtspChannel, url } = this;

    this.$_processPacket = (packet) => this.processPacket(packet);
    this.$_processInfo = (info) => this.processInfo(info);

    const setTracks = (tracks) => {
      rtspChannel.tracks = tracks;

      tracks.forEach((track) => {
        switch (track.mediatype) {
          case 'video':
          case 'audio':
            rtspChannel.on(track.trackID, this.$_processPacket);
            break;

          case 'application':
            rtspChannel.on(track.trackID, this.$_processInfo);
            break;

          default:
            break;
        }
      });
    };

    return new Promise((resolve, reject) => {
      const done = ([status, tracks]) => {
        if (this.status === 'TEARDOWN') {
          return reject();
        }

        setTracks(tracks);

        this.status = 'DESCRIBE';

        return resolve([status, tracks]);
      };

      const params = this.getAuthParams();

      rtspChannel.sendDescribe(url, params /* , true */).then(done, () => {
        // try again without backchannel
        rtspChannel.sendDescribe(url, params).then(done, reject);
      });
    });
  }

  sendSetup() {
    if (!this.checkStatusIsAllowed(['DESCRIBE', 'PLAY'])) {
      return Promise.reject();
    }

    const { rtspChannel, url } = this;

    return new Promise((resolve, reject) => {
      Promise.all(
        rtspChannel.tracks.map((track) => {
          const rPathWithParams = /(\S+)\?(\S*)/;

          if (rPathWithParams.test(url)) {
            return rtspChannel.sendSetup(url.replace(
              rPathWithParams, (_, p1, p2) => `${p1}/trackID=${track.trackID}?${p2}`
            ));
          }
          return rtspChannel.sendSetup(`${url}/trackID=${track.trackID}`);
        })
      ).then((responses) => {
        if (this.status === 'TEARDOWN') {
          return reject();
        }

        const session = responses[0][1];

        this.status = 'SETUP';
        this.session = session;

        return resolve(['200', session]);
      }, reject);
    });
  }

  sendPlay(rate=1) {
    if (!this.checkStatusIsAllowed(['SETUP', 'PLAY', 'PAUSE'])) {
      return Promise.reject();
    }

    const { url, session, rtspChannel } = this;

    return rtspChannel.sendPlay(url, session, rate).then(() => {
      this.status = 'PLAY';
    });
  }

  sendPause() {
    if (!this.checkStatusIsAllowed(['PLAY'])) {
      return Promise.reject();
    }

    const { url, session, status, rtspChannel } = this;

    if (status === 'PAUSE') {
      return Promise.resolve();
    }

    return rtspChannel.sendPause(url, session).then(() => {
      this.status = 'PAUSE';
    });
  }

  sendChange(rate) {
    if (!this.checkStatusIsAllowed(['PLAY', 'PAUSE'])) {
      return Promise.reject();
    }

    const { url, session, rtspChannel } = this;

    return rtspChannel.sendChange(url, session, rate);
  }

  sendTeardown() {
    const {
      rtspChannel, url, session,
      $_processPacket, $_processInfo
    } = this;

    return rtspChannel.sendTeardown(url, session).finally(() => {
      this.status = 'TEARDOWN';

      if (!rtspChannel.tracks) {
        return;
      }

      rtspChannel.tracks.forEach((track) => {
        const method = track.mediatype === 'application/json' ? $_processInfo : $_processPacket;

        rtspChannel.off(track.trackID, method);
        rtspChannel.removeTrack(track.trackID);

        delete this[method];
      });
    });
  }

  play() {
    if (this.status === 'PAUSE') {
      return this.sendPlay();
    }

    const playSteps = () => this.sendOptions()
      .then(() => this.sendDescribe())
      .then(() => this.sendSetup())
      .then(() => this.sendPlay())
      .catch((e) => {
        console.error(e);
        return Promise.reject(e);
      });
    if (this.status === 'TEARDOWN') {
      return playSteps();
    }
    return this.stop().then(() => playSteps());
  }

  pause() {
    if (this.status === 'PAUSE') {
      return Promise.resolve();
    }

    return this.sendPause();
  }

  change(rate) {
    return this.sendChange(rate);
  }

  stop() {
    if (this.status === 'TEARDOWN') {
      return Promise.resolve();
    }

    return this.sendTeardown()
      .catch((err) => {
        console.warn(err);
      });
  }

  processPacket(packet) {
    this.trigger('packet', packet);
  }

  processInfo(info) {
    this.trigger('info', info);
  }

  setUrl(url) {
    this.url = url;
  }
};

export default MicroEvent.mixin(RtspHandler);
