import MicroEvent from '@vivotek/lib-utility/microevent';
import NotifyHandler from '../handler/NotifyHandler';
import Notify from '../model/Notify';
import Metj from '../model/Metj';

class AbstractPlayer {
  constructor() {
    this.player = null;
    this.notifyHandler = new NotifyHandler();
    this.isInited = false;
    this.paused = false;
    this.playing = false;
    this.playbackOffset = 0;
    this.playbackReady = false;
  }

  get currentTime() {
    return this.player.currentTime;
  }

  get playbackRate() {
    return this.player.playbackRate;
  }

  set playbackRate(rate) {
    if (rate > 0) {
      this.player.playbackRate = rate;
    }
  }

  onInited(player) {
    this.isInited = true;
    this.player = player;
  }

  onPlay() {
    this.trigger('play');
    this.playing = true;
  }

  onTimeupdate() {
    this.trigger('timeupdate');

    const currentTime = this.currentTime * 1000;
    const { notifyHandler } = this;
    notifyHandler.getExpiredEvents(currentTime)
      .forEach((event) => {
        let type;

        if (event instanceof Notify) {
          type = 'notify';
        } else if (event instanceof Metj) {
          type = 'metj';
        }

        if (type) {
          this.trigger('notify', { type, event });
        }
      });
  }

  onPause() {}

  appendPacket({ info }) {
    if ((info.timestamp && info.timestamp.video >= 0)
      && !info.preroll && !this.playbackReady) {
      this.playbackOffset = info.timestamp.video / 1000;
      this.playbackReady = true;
    }

    this.notifyHandler.appendNotify(info);
  }

  appendMetj(metj) {
    this.notifyHandler.appendMetj(metj);
  }

  appendMetx(xmlDoc) {
    this.notifyHandler.appendMetx(xmlDoc);
  }

  switch() {
    this.pause();
    this.notifyHandler.clear();
    this.playing = false;
    this.playbackReady = false;
  }

  play() {
    if (this.paused) {
      this.player.play();
      this.paused = false;
    }
  }

  pause() {
    this.player.pause();
    this.paused = true;
  }

  stop() {
    this.playing = false;
  }

  destroy() {
    this.notifyHandler.destroy();
    this.player.release();
    this.player = null;
  }

  getReferenceNotify() {
    return this.notifyHandler.getReferenceNotify();
  }
}

export default MicroEvent.mixin(AbstractPlayer);
