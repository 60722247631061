export default function b64toBlob(b64Data, contentType = '', sliceSize = 512) {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    byteArrays.push(Uint8Array.from(slice, (c) => c.charCodeAt(0)));
  }

  return new Blob(byteArrays, { type: contentType });
}
